<template>
	<div class="i">
		<div class="i-title">入库信息</div>
		<div class="i-content">
			<el-row>
				<el-col :span="8">
					<span>入库时间:</span>
					<p>{{info.time}}</p>
				</el-col>
				<el-col :span="8">
					<span>关联采购单:</span>
					<p>{{info.purchaseNumber || '无'}}</p>
				</el-col>
				<el-col :span="8">
					<span>关联快递单号:</span>
					<p>{{info.number}}</p>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="8">
					<span>入库总数量:</span>
					<p>{{info.itemRtnList?.length}}</p>
				</el-col>
				<el-col :span="8">
					<span>备注:</span>
					<p>{{info.remarks}}</p>
				</el-col>
			</el-row>
		</div>
		<div class="i-title" style="padding-bottom: 20px;">产品信息</div>
		<lida-table
			:cutHeight="0"
			:data="data"
			border
			:pagination="false"
			ref="table">
				<lida-table-column label="产品信息" fixed>
					<template #default="scope">
						<div class="g-info">
							<div class="g-icon">
								<img :src="scope.row.src"/>
							</div>
							<div class="g-right">
								<div class="g-r-title">
									{{scope.row.productName}}
									<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
								</div>
								<div class="g-r-data">
									{{scope.row.barCode || '无'}}
									<span>|</span>
									{{scope.row.seriesName || '无'}}
									<span>|</span>
									{{scope.row.skuNumber || '无'}}
								</div>
							</div>
						</div>
					</template>
				</lida-table-column>
				<lida-table-column label="订货号" prop="articleNo" />
				<lida-table-column label="入库数量" prop="qty" />
				<!-- <lida-table-column label="操作" fixed="right">
					<template #default="scope">
						<el-button size="mini" @click="deleteRow(scope)" type="text">移除</el-button>
					</template>
				</lida-table-column> -->
		</lida-table>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	export default{
		data(){
			return{
				info:{},  //入库信息
				data:[],  //商品数据
			}
		},
		methods:{
			...mapActions('stockModule',[
				'stockInfo',  //入库出库详情
			]),
			getinfo(){ //获取详情
				this.stockInfo(this.$route.query.id).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						console.log(data);
						this.info = data;
						this.data = data.itemRtnList;
					}
				})
			}
		},
		created(){
			this.getinfo();
		}
	}
</script>

<style scoped lang="less">
	/deep/ .el-row{
		padding:15px 0;
		.el-col{
			font-size: 14px;
			display: flex;
			align-items: center;
			span{
				color: #999999;
				width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				
			}
			p{
				flex: 1;
			}
		}
	}
	.i-content{
		padding: 0px 100px;
	}
	.i-title{
		color: @dh-color;
		font-size: 14px;
		font-weight: bold;
		padding-top: 10px;
	}
</style>
